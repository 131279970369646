import { create_shareable_link, remove_invite_code } from "api/organization";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import LoadingSpinner from "components/LoadingSpinner";
import { CopyCheckIcon, CopyIcon, LinkIcon } from "lucide-react";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { Button } from "@/components/ui/button";

export default function InviteLink() {
  const [inviteLinkRole, setInviteLinkRole] = useState("member");
  const [hasCopied, setHasCopied] = useState(false);

  const {
    data: inviteLink,
    trigger: createShareableLink,
    isMutating: isCreatingShareableLink,
    reset: resetInviteLink,
  } = useSWRMutation(
    "create_shareable_link",
    () => create_shareable_link({ role: inviteLinkRole }),
    { onSuccess: ({ url }) => handleCopy(url) }
  );

  const handleCopy = (url) => {
    setHasCopied(true);
    navigator.clipboard.writeText(url);
  };

  const handleRoleChange = (v) => {
    resetInviteLink();
    setHasCopied(false);
    setInviteLinkRole(v);
  };

  return (
    <div>
      <div className="flex gap-x-2 items-center">
        <Button
          className="btn btn-fuchsia"
          onClick={createShareableLink}
          disabled={isCreatingShareableLink}
        >
          <LinkIcon /> {hasCopied ? "Copied!" : "Copy Link"}
        </Button>
        <Select
          className="w-fit"
          value={inviteLinkRole}
          onValueChange={handleRoleChange}
        >
          <SelectTrigger className="w-fit">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="member">Member</SelectItem>
            <SelectItem value="admin">Admin</SelectItem>
          </SelectContent>
        </Select>
        {inviteLink && (
          <div className="hover:border-fuchsia border-1 rounded-md px-1">
            {inviteLink.url}
          </div>
        )}
      </div>
    </div>
  );
}
