import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { LoadingPage } from "components";
import { format } from "date-fns";

export default function GrantEvent({ eventInfo, grantInfo, isLoading }) {
  const [isGrantDescriptionOpen, setIsGrantDescriptionOpen] = useState(false);
  if (isLoading) return <LoadingPage />;

  console.log(eventInfo);

  return (
    <>
      {/* <DialogContent className="max-w-4xl max-h-screen overflow-y-scroll">
        <div className="flex gap-x-4">
          {bookmarked ? (
            <button
              className="btn-secondary"
              onClick={unbookmarkCall}
              disabled={isUnbookmarking}
            >
              Unbookmark
              {isUnbookmarking ? (
                <LoadingSpinner />
              ) : (
                <BookmarkIcon className="w-4 h-4" />
              )}
            </button>
          ) : (
            <button
              className="btn-secondary"
              onClick={bookmark}
              disabled={isBookmarking}
            >
              Bookmark{" "}
              {isBookmarking ? (
                <LoadingSpinner />
              ) : (
                <BookmarkIcon className="w-4 h-4" />
              )}
            </button>
          )}

          {url && (
            <a
              className="btn-secondary hover:opacity-80"
              href={url}
              target="_blank"
            >
              Open Link <ExternalLink className="w-4 h-4" />
            </a>
          )}
        </div>
        <DialogHeader>
          <DialogTitle>
            <span className="text-2xl underline font-semibold">{title}</span>
          </DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="text-black flex flex-col gap-y-2">
          <div>{agency}</div>
          <div className="flex flex-wrap gap-x-4 items-center">
            {posted_date && (
              <div className="text-base">
                <span className="font-semibold">Posted: </span>
                {remove_timestamp(posted_date)}
              </div>
            )}
            {close_date && (
              <div className="text-base">
                <span className="font-semibold">Closes: </span>
                {remove_timestamp(close_date)}
              </div>
            )}
            {relevance && <span>{pill[relevance]}</span>}
            {award_ceiling && (
              <span className="border-1 border-green-500 bg-green-200 text-xs p-1 rounded-lg">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(award_ceiling)}
              </span>
            )}
          </div>
          <div>{description}</div>
        </div>
      </DialogContent> */}
      {/* <DialogHeader>
        <DialogTitle>Grant Due</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <div className="flex justify-end">
        <Link
          to={`/project/${eventInfo?.project_id}/admin-space/selected-call`}
          className="btn btn-fuchsia"
        >
          Go to Project
        </Link>
      </div>
      <ScrollArea className="max-h-96">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-semibold">Agency</TableCell>
              <TableCell>{grantInfo?.agency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Grant</TableCell>
              <TableCell>{grantInfo?.title}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-semibold">Description</TableCell>
              <TableCell>
                <Collapsible
                  className="text-sm"
                  open={isGrantDescriptionOpen}
                  onOpenChange={(o) => setIsGrantDescriptionOpen(o)}
                >
                  <CollapsibleTrigger className="flex items-center hover:text-fuchsia">
                    {isGrantDescriptionOpen ? "Hide" : "See"} Grant Information
                    <ChevronDownIcon
                      aria-expanded={isGrantDescriptionOpen}
                      className="aria-expanded:rotate-180"
                    />
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div>{grantInfo.description}</div>
                  </CollapsibleContent>
                </Collapsible>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Posted Date</TableCell>
              <TableCell>
                {grantInfo?.posted_date &&
                  format(new Date(grantInfo?.posted_date), "E PP")}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-semibold">Due</TableCell>
              <TableCell>
                {grantInfo?.close_date &&
                  format(new Date(grantInfo?.close_date), "E PP")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Project</TableCell>
              <TableCell>{eventInfo?.project_name}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-semibold">Award Ceiling</TableCell>
              <TableCell>{grantInfo?.award_ceiling}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">Url</TableCell>
              <TableCell>{grantInfo?.url}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">
                Eligible Applicants
              </TableCell>
              <TableCell>{grantInfo?.eligible_applicant}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">
                Funding Instrument Type
              </TableCell>
              <TableCell>{grantInfo?.funding_instrument_type}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ScrollArea> */}
    </>
  );
}
