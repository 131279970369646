import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { DialogClose } from "@/components/ui/dialog";
import { remove_timestamp } from "@/lib/utils";
import { delete_selected_call, get_call_info } from "api";
import { ErrorPage, LoadingPage } from "components";
import { ExternalLinkIcon } from "lucide-react";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function SelectedCall({ projectId, grantId, hasEditorAccess }) {
  const {
    data: grant,
    isLoading: isGrantLoading,
    error: isGrantError,
  } = useSWR(`get_grant_info/${grantId}`, () =>
    get_call_info({ call_id: grantId })
  );

  const {
    trigger: deleteProjectCall,
    isMutating: isDeletingProject,
    error: deleteProjectError,
  } = useSWRMutation(
    "delete_project_call",
    () => delete_selected_call({ project_id: projectId }),
    {
      onSuccess: () => {
        mutate(`get_project/${projectId}`);
      },
    }
  );

  if (isGrantLoading) return <LoadingPage />;
  if (isGrantError) return <ErrorPage />;

  const { agency, close_date, description, posted_date, title, url } = grant;
  const newCloseDate = remove_timestamp(close_date) || "Unavailable";
  const newPostedDate = remove_timestamp(posted_date) || "Unavailable";

  return (
    <div className="flex flex-col">
      Your currently selected call:
      <Dialog>
        <DialogTrigger>
          <div className="flex flex-col border-[1px] rounded-lg p-4 gap-y-2 hover:border-fuchsia text-left">
            <div>
              <span className="text-2xl underline font-semibold">{title}</span>
            </div>
            <div>{agency}</div>
            <div className="text-base">
              <strong>Posted</strong>: {newPostedDate}
            </div>
            <div className="text-base">
              <strong>Closes</strong>: {newCloseDate}
            </div>
            <div className="break-wordsp-2 text-base">
              {description.substring(0, 300) + "..."}
            </div>
          </div>
        </DialogTrigger>
        <DialogContent className="max-w-4xl max-h-screen overflow-y-scroll">
          <div className="text-black flex flex-col gap-y-2">
            <div>
              {url ? (
                <a href={url}>
                  {title} <ExternalLinkIcon className="inline" />
                </a>
              ) : (
                <span className="text-2xl underline font-semibold">
                  {title}
                </span>
              )}
            </div>
            <div>{agency}</div>
            <div>Posted: {newPostedDate}</div>
            <div>Closes: {newCloseDate}</div>
            <div className="break-wordsp-2 text-base">{description}</div>
          </div>
        </DialogContent>
      </Dialog>
      {hasEditorAccess && (
        <Dialog>
          <DialogTrigger className="w-fit">
            <span className="text-red-500 hover:underline">
              Remove the selected call
            </span>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                Are you sure you want to delete this call?
              </DialogTitle>
              <DialogDescription></DialogDescription>
            </DialogHeader>
            <div className="text-black flex flex-col gap-y-4">
              <div className="flex justify-between">
                <DialogClose asChild>
                  <button className="text-zinc-600 hover:underline">
                    Cancel
                  </button>
                </DialogClose>
                <button
                  onClick={deleteProjectCall}
                  className="text-red-500 hover:underline"
                >
                  Remove Call
                </button>
              </div>
              <div>
                {deleteProjectError && (
                  <span className="text-red-500">
                    There was an issue removing the selected call from this
                    project.
                  </span>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
