import { useIsAdmin } from "@/hooks/use-is-admin";
import { remove_timestamp } from "@/lib/utils";
import { create_customer_portal, uncancel_plan } from "api/billing";
import { LoadingSpinner } from "components";
import { TriangleAlertIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { v4 as uuidv4 } from "uuid";

export default function CurrentPlan({ customerPlan }) {
  const { isAdmin } = useIsAdmin();
  const {
    trigger: createCustomerPortal,
    isMutating: isCreatingCustomerPortal,
  } = useSWRMutation("create_customer_portal", () => create_customer_portal(), {
    onSuccess: ({ url }) => window.open(url, "_blank"),
  });

  const {
    data: hasUncanceled,
    trigger: uncancelPlan,
    isMutating: isUncanceling,
  } = useSWRMutation(
    "uncancel_plan",
    () => uncancel_plan({ idempotency_key: uuidv4() }),
    {
      onSuccess: () => mutate("get_customer_plan"),
    }
  );

  return (
    <div className="flex justify-center p-4 border-1 rounded-md">
      <div className="max-w-3xl w-full flex flex-col gap-y-4">
        <div>
          <div className="flex justify-between items-center">
            <div className="font-semibold text-lg text-gray-500 capitalize">
              Current Plan
            </div>
          </div>
          <div>
            <span className="capitalize">{customerPlan.tier}</span>
          </div>
        </div>

        {!customerPlan.trial && customerPlan.current_period_end && (
          <div>
            <div className="font-semibold text-lg text-gray-500">
              Next Billing Date
            </div>
            {customerPlan?.cancel_at_period_end ? (
              <div className="flex w-full text-base border-yellow-500 border-2 p-2 rounded-lg bg-yellow-100 items-center gap-x-4">
                <TriangleAlertIcon className="h-8 w-8" />
                <span>
                  Your membership will expire on{" "}
                  {remove_timestamp(customerPlan.current_period_end)}. We're
                  sorry to see you go and hope to welcome you back in the
                  future!{" "}
                  {hasUncanceled ? (
                    <span>Thank you for renewing your membership!</span>
                  ) : (
                    <span
                      className="hover:underline text-fuchsia hover:cursor-pointer disabled:text-gray-500"
                      onClick={uncancelPlan}
                      disabled={isUncanceling}
                    >
                      Renew your membership here.{" "}
                      {isUncanceling && <LoadingSpinner />}
                    </span>
                  )}
                </span>
              </div>
            ) : customerPlan?.current_period_end ? (
              remove_timestamp(customerPlan.current_period_end)
            ) : (
              "Not Available"
            )}
          </div>
        )}
        {customerPlan.trial && (
          <div>
            <div className="font-semibold text-lg text-gray-500">
              Trial End Date
            </div>
            {remove_timestamp(customerPlan.current_period_end)}
          </div>
        )}
      </div>
      {isAdmin && (
        <>
          {!customerPlan.prompt_upgrade && (
            <div className="flex flex-col gap-4">
              <button
                className="flex items-center text-sm border-1 rounded-md p-1 hover:border-fuchsia text-nowrap text-center"
                onClick={() => createCustomerPortal()}
                disabled={isCreatingCustomerPortal}
              >
                Update Payment {isCreatingCustomerPortal && <LoadingSpinner />}
              </button>
              <Link
                to="/settings/billing/manage-plan"
                className="flex items-center text-sm border-1 rounded-md p-1 hover:border-fuchsia text-center justify-center"
              >
                Manage Plan
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}
