import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useSettings } from "@/hooks/use-settings";
import { export_template_to_google_drive } from "api/write_space";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { FaGoogleDrive } from "react-icons/fa6";
import useSWRMutation from "swr/mutation";

export default function ExportToDrive({ templateId }) {
  const { settings } = useSettings();
  const [exportMethod, setExportMethod] = useState(null);

  const {
    trigger: exportToDrive,
    isMutating: isExporting,
    data: hasExported,
    reset: resetExportToDrive,
  } = useSWRMutation(
    "export_to_drive",
    () =>
      export_template_to_google_drive({
        template_id: templateId,
        export_type: exportMethod,
      }),
    {
      onSuccess: (data) => {
        window.open(data.url, "_blank");
      },
    }
  );

  if (settings?.prompt_upgrade)
    return (
      <Tooltip>
        <TooltipTrigger
          className="btn-fuchsia text-sm"
          disabled={settings?.prompt_upgrade}
        >
          <FaGoogleDrive />
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">
          Please upgrade to pro to use this feature.
        </TooltipContent>
      </Tooltip>
    );

  if (!settings?.google_drive_integration)
    return (
      <Tooltip>
        <TooltipTrigger className="btn-fuchsia text-sm" disabled={true}>
          <FaGoogleDrive />
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">
          Please connect to Google drive in your integration settings.
        </TooltipContent>
      </Tooltip>
    );

  return (
    <Dialog onOpenChange={resetExportToDrive}>
      <DialogTrigger className="btn btn-fuchsia">
        <FaGoogleDrive />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Export to Google Drive</DialogTitle>
          <DialogDescription>
            Export your outline to google drive.
          </DialogDescription>
        </DialogHeader>
        <Select value={exportMethod} onValueChange={setExportMethod}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="new">New document</SelectItem>
            <SelectItem value="most_recent">
              Overwrite most recent document
            </SelectItem>
          </SelectContent>
        </Select>

        <div className="flex justify-end">
          {hasExported ? (
            <button className="btn btn-fuchsia">Exported!</button>
          ) : (
            <button
              onClick={exportToDrive}
              disabled={isExporting || !exportMethod}
              className="btn btn-fuchsia"
            >
              Export {isExporting && <LoadingSpinner />}
            </button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
