import { AutosizeTextarea } from "@/components/ui/autosizetextarea";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { DatePicker } from "@/components/ui/datepicker";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { add_user_call } from "api/grant_space";
import { LoadingSpinner } from "components";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useRef, useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

const applicantOptions = [
  { value: 99, label: "Unrestricted" },
  { value: 0, label: "State governments" },
  { value: 1, label: "County governments" },
  { value: 2, label: "City or township governments" },
  { value: 4, label: "Special district governments" },
  { value: 5, label: "Independent school districts" },
  {
    value: 6,
    label: "Public and State controlled institutions of higher education",
  },
  {
    value: 7,
    label: "Native American tribal governments (Federally recognized)",
  },
  { value: 8, label: "Public housing authorities/Indian housing authorities" },
  { value: 11, label: "Native American tribal organizations " },
  {
    value: 12,
    label:
      "Nonprofits having a 501 (c) (3) status with the IRS, other than institutions of higher education",
  },
  {
    value: 13,
    label:
      "Nonprofits that do not have a 501 (c) (3) status with the IRS, other than institutions of higher education",
  },
  { value: 20, label: "Private institutions of higher education" },
  { value: 21, label: "Individuals" },
  { value: 22, label: "For-profit organizations other than small businesses" },
  { value: 23, label: "Small businesses" },
  { value: 25, label: "Others" },
];

export default function AddACall({ projectId }) {
  const [title, setTitle] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [closeDate, setCloseDate] = useState(null);
  const [awardFloor, setAwardFloor] = useState(0);
  const [eligibleApplicant, setEligibleApplicant] = useState(null);
  const [fundingInstrumentType, setFundingInstrumentType] = useState("");
  const [description, setDescription] = useState("");

  const [postedDate, setPostedDate] = useState("");
  // const [fiscalYear, setFiscalYear] = useState("");
  const [url, setUrl] = useState("");

  const { trigger: createNewCall, isMutating: isCreatingCall } = useSWRMutation(
    "create_new_call",
    () =>
      add_user_call({
        project_id: projectId,
        call_data: {
          title: title,
          agency_name: agencyName,
          close_date: format(closeDate, "yyyy-MM-dd"),
          description: description,
          award_floor: awardFloor,
          eligible_applicants: eligibleApplicant,
          funding_instrument_type: fundingInstrumentType,
        },
      }),
    { onSuccess: () => mutate(`get_project/${projectId}`) }
  );

  // {
  //   "project_id": "96",
  //   "call_data": {
  //     "title": "TEST TITLE",
  //     "agency_name": "TEST AGENCY",
  //     "close_date": "2025-01-25",
  //     "description": "TEST DESCRIPTION",
  //     "award_floor": "20000",
  //     "eligible_applicants": 4,
  //     "funding_instrument_type": "CA"
  //   }
  // }

  // console.log(closeDate ? format(closeDate, "yyyy-MM-dd") : "")

  const isFilledOut =
    title.length > 0 &&
    agencyName.length > 0 &&
    closeDate &&
    fundingInstrumentType.length > 0 &&
    eligibleApplicant !== null &&
    description.length > 0;

  return (
    <>
      <div className="grid grid-cols-2 gap-x-2 gap-y-4 p-2">
        <div className="col-span-2 font-semibold">Required Information</div>
        <div>
          <div>Title</div>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div>
          <div>Agency Name</div>
          <Input
            value={agencyName}
            onChange={(e) => setAgencyName(e.target.value)}
          />
        </div>
        <div>
          <div>Close Date</div>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !closeDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {closeDate ? (
                  format(closeDate, "PPP")
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={closeDate}
                onSelect={setCloseDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          {/* <DatePicker value={closeDate} onChangeDate={onChangeDate} /> */}
        </div>
        <div>
          <div>
            Posted Date{" "}
            <span className="text-muted-foreground">(optional)</span>
          </div>

          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !postedDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {postedDate ? (
                  format(postedDate, "PPP")
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={postedDate}
                onSelect={setPostedDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          {/* <DatePicker
            value={postedDate}
            onChangePostedDate={onChangeDate}
          /> */}
        </div>
        <div>
          <div>Award Floor</div>
          <Input
            type="number"
            min="0"
            value={awardFloor}
            onChange={(e) => setAwardFloor(e.target.value)}
          />
        </div>
        <div>
          <div>Elligible Applicants</div>
          <Select
            value={eligibleApplicant}
            onValueChange={(v) => setEligibleApplicant(v)}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {applicantOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <div>Funding Instrument Type</div>
          <Select
            value={fundingInstrumentType}
            onValueChange={(v) => setFundingInstrumentType(v)}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="G">Grant</SelectItem>
              <SelectItem value="CA">Cooperative Agreement</SelectItem>
              <SelectItem value="PC">Procurement Contract</SelectItem>
              <SelectItem value="O">Other</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {/* <div>
          <div>
            Fiscal Year{" "}
            <span className="text-muted-foreground">(optional)</span>
          </div>
          <Input
            value={fiscalYear}
            onChange={(e) => setFiscalYear(e.target.value)}
          />
        </div> */}
        <div>
          <div>
            URL <span className="text-muted-foreground">(optional)</span>
          </div>
          <Input value={url} onChange={(e) => setUrl(e.target.value)} />
        </div>
        <div className="col-span-2">
          <div>Description</div>
          <AutosizeTextarea
            className="h-40"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <button
          onClick={createNewCall}
          className="btn btn-fuchsia"
          disabled={!isFilledOut || isCreatingCall}
        >
          Add {isCreatingCall && <LoadingSpinner />}
        </button>
      </div>
    </>
  );
}
