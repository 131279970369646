import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  billing_config,
  cancel_plan,
  downgrade_plan,
  get_customer_plan,
  preview_billing_changes,
  upgrade_plan,
} from "api/billing";
import { ErrorPage, LoadingPage, LoadingSpinner, Title } from "components";
import { Minus, Plus } from "lucide-react";
import { useState } from "react";
// import { useIdempotencyKey } from "stores/useIdempotencyKey";
import { v4 as uuidv4 } from "uuid";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import CancelPlan from "./CancelPlan";
import ChangeNumberOfSeats from "./ChangeNumberOfSeats";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";

const TIER_TO_PLAN = {
  organization: "org_plan_quarterly",
  "organization annual": "org_plan_annual",
  pro: "pro_plan_quarterly",
  "pro annual": "pro_plan_annual",
};
const TIERS = [
  "pro_plan_quarterly",
  "pro_plan_annual",
  "org_plan_quarterly",
  "org_plan_annual",
];

export default function ManagePlan() {
  console.log("hello");
  const {
    data: customerPlan,
    isLoading: isGettingCustomerPlan,
    error: customerPlanError,
  } = useSWR("get_customer_plan", get_customer_plan);

  const {
    data: billingConfig,
    isLoading: isGettingBillingConfig,
    error: billingConfigError,
  } = useSWR("billing_config", billing_config);
  // const { idempotencyKey } = useIdempotencyKey();
  const navigate = useNavigate();
  const [numSeats, setNumSeats] = useState(customerPlan?.seats_purchased ?? 1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isForceUpgrade, setIsForceUpgrade] = useState(false);

  const {
    data: invoice,
    trigger: previewChanges,
    isMutating: isGeneratingPreview,
    reset: resetInvoice,
  } = useSWRMutation(`preview_changes/${numSeats}`, () =>
    preview_billing_changes({
      idempotency_key: uuidv4(),
      stripe_price_id: selectedPlan.id,
      quantity: selectedPlan.key.startsWith("org") ? numSeats : 1,
    })
  );

  const { trigger: upgradePlan, isMutating: isUpgradingPlan } = useSWRMutation(
    "upgrade_plan",
    () =>
      upgrade_plan({
        stripe_price_id: selectedPlan.id,
        quantity: selectedPlan.key.startsWith("org") ? numSeats : 1,
        idempotency_key: uuidv4(),
        force_upgrade: isForceUpgrade,
        proration_date: invoice.proration_date,
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          mutate("get_customer_plan");
          navigate("/settings/billing");
        }, 500);
      },
    }
  );

  const { trigger: downgradePlan, isMutating: isDowngradingPlan } =
    useSWRMutation(
      "downgrade_plan",
      () =>
        downgrade_plan({
          stripe_price_id: selectedPlan.id,
          quantity: selectedPlan.key.startsWith("org") ? numSeats : 1,
          idempotency_key: uuidv4(),
        }),
      {
        onSuccess: () => {
          // Wait for server to finish then redirect the user
          setTimeout(() => {
            mutate("get_customer_plan");
            navigate("/settings/billing");
          }, 500);
        },
      }
    );

  if (isGettingCustomerPlan || isGettingBillingConfig) return <LoadingPage />;
  if (customerPlanError || billingConfigError) return <ErrorPage />;

  const quarterly_pro_plan = billingConfig.prices.find(
    ({ key }) => key === "pro_plan_quarterly"
  );
  const quarterly_org_plan = billingConfig.prices.find(
    ({ key }) => key === "org_plan_quarterly"
  );
  const annual_pro_plan = billingConfig.prices.find(
    ({ key }) => key === "pro_plan_annual"
  );
  const annual_org_plan = billingConfig.prices.find(
    ({ key }) => key === "org_plan_annual"
  );

  const formatUSD = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const customerCurrentPlan = billingConfig.prices.find(
    ({ key }) => TIER_TO_PLAN[customerPlan.tier] === key
  );

  const currentTier = customerCurrentPlan?.key
    ? TIERS.indexOf(customerCurrentPlan?.key)
    : -1;

  const selectedPlanTier = selectedPlan?.key
    ? TIERS.indexOf(selectedPlan?.key)
    : -1;

  const hasScheduledPlans = customerPlan?.schedule?.length > 0;

  return (
    <div>
      <Tabs defaultValue="quarterly">
        <div className="flex justify-between">
          <div>
            <Title className="text-3xl" value="Manage Plan" />
          </div>
          <div className="flex gap-2">
            <ChangeNumberOfSeats
              hasScheduledPlans={customerPlan?.schedule?.length > 0}
              numberOfMembers={customerPlan.seats_filled}
              numberOfSeats={customerPlan.seats_purchased}
              org_stripe_id={customerCurrentPlan.id}
            />
            <TabsList>
              <TabsTrigger value="quarterly">Quarterly</TabsTrigger>
              <TabsTrigger value="yearly">
                Yearly <span> (SAVE 17%)</span>
              </TabsTrigger>
            </TabsList>
          </div>
        </div>
        <Dialog
          onOpenChange={() => {
            resetInvoice();
          }}
        >
          <TabsContent value="quarterly">
            <div className="grid grid-cols-3 gap-2">
              {quarterly_pro_plan && (
                <div className="border-1 rounded-lg p-2 flex flex-col">
                  <span className="font-semibold">Pro</span>
                  <span className="text-2xl">
                    {formatUSD.format(
                      quarterly_pro_plan.unit_amount /
                        100 /
                        quarterly_pro_plan.recurring.interval_count
                    )}
                    <span className="text-base text-muted-foreground">
                      {" "}
                      / {quarterly_pro_plan.recurring.interval}
                    </span>{" "}
                    <span className="text-base text-muted-foreground whitespace-nowrap">
                      billed for 3 months
                    </span>
                  </span>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => setSelectedPlan(quarterly_pro_plan)}
                      disabled={
                        TIER_TO_PLAN[customerPlan.tier] ===
                        quarterly_pro_plan.key
                      }
                    >
                      {TIER_TO_PLAN[customerPlan.tier] ===
                      quarterly_pro_plan.key
                        ? "Current Plan"
                        : "Select Plan"}
                    </Button>
                  </DialogTrigger>
                </div>
              )}
              {quarterly_org_plan && (
                <div className="border-1 rounded-lg p-2 flex flex-col">
                  <span className="font-semibold">Organization</span>
                  <span className="text-2xl">
                    {formatUSD.format(
                      quarterly_org_plan.unit_amount /
                        100 /
                        quarterly_org_plan.recurring.interval_count
                    )}
                    <span className="text-base text-muted-foreground whitespace-nowrap">
                      {" "}
                      / {quarterly_org_plan.recurring.interval} / user
                    </span>{" "}
                    <span className="text-base text-muted-foreground whitespace-nowrap">
                      billed for 3 months
                    </span>
                  </span>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => setSelectedPlan(quarterly_org_plan)}
                      disabled={
                        TIER_TO_PLAN[customerPlan.tier] ===
                        quarterly_org_plan.key
                      }
                    >
                      {TIER_TO_PLAN[customerPlan.tier] ===
                      quarterly_org_plan.key
                        ? "Current Plan"
                        : "Select Plan"}
                    </Button>
                  </DialogTrigger>
                </div>
              )}
            </div>
          </TabsContent>
          <TabsContent value="yearly">
            <div className="grid grid-cols-3 gap-2">
              {annual_pro_plan && (
                <div className="border-1 rounded-lg p-2 flex flex-col">
                  <span className="font-semibold">Pro</span>
                  <span className="text-2xl">
                    {formatUSD.format(
                      annual_pro_plan.unit_amount /
                        100 /
                        annual_pro_plan.recurring.interval_count
                    )}
                    <span className="text-base text-muted-foreground">
                      {" "}
                      / {annual_pro_plan.recurring.interval}
                    </span>
                  </span>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => setSelectedPlan(annual_pro_plan)}
                      disabled={
                        TIER_TO_PLAN[customerPlan.tier] === annual_pro_plan.key
                      }
                    >
                      {TIER_TO_PLAN[customerPlan.tier] === annual_pro_plan.key
                        ? "Current Plan"
                        : "Select Plan"}
                    </Button>
                  </DialogTrigger>
                </div>
              )}
              {annual_org_plan && (
                <div className="border-1 rounded-lg p-2 flex flex-col">
                  <span className="font-semibold">Pro</span>
                  <span className="text-2xl">
                    {formatUSD.format(
                      annual_pro_plan.unit_amount /
                        100 /
                        annual_pro_plan.recurring.interval_count
                    )}
                    <span className="text-base text-muted-foreground">
                      {" "}
                      / {annual_pro_plan.recurring.interval}
                    </span>
                  </span>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() => setSelectedPlan(annual_org_plan)}
                    >
                      Select Plan
                    </Button>
                  </DialogTrigger>
                </div>
              )}
            </div>
          </TabsContent>
          <DialogContent className="max-w-3xl">
            <DialogHeader>
              <DialogTitle className="capitalize">
                {selectedPlan?.key.replaceAll("_", " ")}
              </DialogTitle>
              <DialogDescription>Update plan</DialogDescription>
            </DialogHeader>
            {selectedPlan?.key.startsWith("org") && (
              <>
                <div className="flex gap-x-2 items-center justify-end">
                  <Label className="font-semibold">Number of Seats</Label>
                  <button
                    className="enabled:hover:text-fuchsia disabled:text-muted-foreground"
                    onClick={() => setNumSeats((x) => x - 1)}
                    disabled={numSeats <= 1}
                  >
                    <Minus className="size-4" />
                  </button>
                  <Input
                    value={numSeats}
                    onChange={(e) => setNumSeats(parseInt(e.target.value))}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none min-w-fit w-10"
                    type="number"
                    min="1"
                  />
                  <button
                    className="enabled:hover:text-fuchsia disabled:text-muted-foreground"
                    onClick={() => setNumSeats((x) => x + 1)}
                  >
                    <Plus className="size-4" />
                  </button>
                </div>
                {customerPlan.tier.startsWith("org") &&
                  numSeats < customerPlan.seats_filled && (
                    <span className="text-red-500 text-right text-sm">
                      You cannot update your number of seats to less than your
                      number of members.
                    </span>
                  )}
              </>
            )}
            {selectedPlanTier > currentTier ? (
              <>
                <div className="flex justify-end">
                  <button onClick={previewChanges} className="btn btn-fuchsia">
                    Preview Invoice {isGeneratingPreview && <LoadingSpinner />}
                  </button>
                </div>
                {invoice && (
                  <>
                    <div className="border-t-1 py-4">
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className="grow w-full text-right"></TableHead>
                            <TableHead className="text-right"></TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {invoice.upcoming_invoice.lines.data.map(
                            ({ amount, description }) => (
                              <TableRow className="text-right">
                                <TableCell className="text-muted-foreground text-sm">
                                  {description}
                                </TableCell>
                                <TableCell className="text-lg font-mono">
                                  {formatUSD.format(amount / 100)}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                          <TableRow className="text-right">
                            <TableCell className="text-muted-foreground text-sm">
                              Total Due
                            </TableCell>{" "}
                            <TableCell className="text-lg font-mono">
                              {formatUSD.format(
                                invoice.upcoming_invoice.amount_due / 100
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                    {hasScheduledPlans && (
                      <>
                        <Checkbox
                          checked={isForceUpgrade}
                          onCheckedChange={setIsForceUpgrade}
                        />{" "}
                        You currently have scheduled updates for the next
                        billing period. Are you sure you want to cancel your
                        updates?
                      </>
                    )}
                    <div className="flex justify-end">
                      <button
                        className="btn btn-fuchsia"
                        onClick={upgradePlan}
                        disabled={
                          isUpgradingPlan ||
                          (hasScheduledPlans && !isForceUpgrade)
                        }
                      >
                        Upgrade to new plan
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              // <Button onClick={upgradePlan} disabled={isUpgradingPlan}>
              //   Upgrade {isUpgradingPlan && <LoadingSpinner />}
              // </Button>
              <div className="flex justify-end">
                <button
                  className="btn btn-fuchsia w-fit"
                  onClick={downgradePlan}
                  disabled={isDowngradingPlan}
                >
                  Downgrade {isDowngradingPlan && <LoadingSpinner />}
                </button>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </Tabs>

      <div className="flex justify-end">
        <CancelPlan hasScheduledPlans={customerPlan?.schedule?.length > 0} />
      </div>
    </div>
  );
}
