import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { cancel_plan } from "api/billing";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIdempotencyKey } from "stores/useIdempotencyKey";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function CancelPlan({ hasScheduledPlans }) {
  const { idempotencyKey } = useIdempotencyKey();
  const navigate = useNavigate();
  const [isForceCancel, setIsForceCancel] = useState(false);
  const { trigger: cancelPlan, isMutating: isCanceling } = useSWRMutation(
    "cancel_plan",
    () =>
      cancel_plan({
        idempotency_key: idempotencyKey,
        force_cancel: isForceCancel,
      }),
    {
      onSuccess: () => {
        mutate("get_customer_plan");
        navigate("/settings/billing");
      },
    }
  );

  return (
    <Dialog>
      <DialogTrigger className="text-red-500 hover:underline">
        Cancel Plan
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cancel Plan</DialogTitle>
          <DialogDescription>
            Are you sure you want to cancel your plan for your next billing
            period?
          </DialogDescription>
        </DialogHeader>
        <div className="flex">
          {hasScheduledPlans && (
            <div className="flex items-center gap-x-2">
              <Label for="force_cancel">
                You currently have scheduled updates. Are you sure you want to
                overwrite your updates?
              </Label>
              <Checkbox
                id="force_cancel"
                checked={isForceCancel}
                onCheckedChange={(v) => setIsForceCancel(v)}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end">
          <button
            className="btn btn-fuchsia"
            onClick={cancelPlan}
            disabled={(hasScheduledPlans && !isForceCancel) || isCanceling}
          >
            Yes, cancel my plan {isCanceling && <LoadingSpinner />}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
