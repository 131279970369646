import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { release_schedule } from "api/billing";
import useSWRMutation from "swr/mutation";
import { useIdempotencyKey } from "stores/useIdempotencyKey";
import { mutate } from "swr";

export default function Schedule({ schedule }) {
  const { idempotencyKey } = useIdempotencyKey();
  const { trigger: releaseSchedule } = useSWRMutation(
    "release_schedule",
    () => release_schedule({ idempotency_key: idempotencyKey }),
    {
      onSuccess: (d) => {
        mutate("get_customer_plan");
      },
    }
  );
  // Assumes there are only two updates.
  const currentSchedule = schedule.find((s) => s.period === "current");
  const futureSchedule = schedule.find((s) => s.period === "future");

  return (
    <div className="">
      <span className="font-semibold">Schedule Updates</span>
      <Table className="border-1 divide-y-0 table-fixed">
        <TableHeader>
          <TableRow className="text-muted-foreground font-semibold divide-x-1">
            <TableCell>Current Schedule</TableCell>
            <TableCell>Future Schedule</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow className="border-0 divide-x-1">
            <TableCell>
              <span className="font-semibold">Tier:</span>{" "}
              {currentSchedule.tier}
            </TableCell>
            <TableCell>
              <span className="font-semibold">Tier:</span> {futureSchedule.tier}
            </TableCell>
          </TableRow>
          <TableRow className="divide-x-1">
            <TableCell>
              <span className="font-semibold">Quantity:</span>{" "}
              {currentSchedule.quantity}
            </TableCell>
            <TableCell>
              <span className="font-semibold">Quantity:</span>{" "}
              {futureSchedule.quantity}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className="flex justify-end">
        <button className="btn text-red-500" onClick={releaseSchedule}>
          Cancel Updates
        </button>
      </div>
    </div>
  );
}
