import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useIsAdmin } from "@/hooks/use-is-admin";
import { create_checkout_session } from "api/billing";
import { LoadingSpinner } from "components";
import useSWRMutation from "swr/mutation";
export default function SelectPlan({ billingConfig }) {
  const { isAdmin } = useIsAdmin();
  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const { trigger: createCheckoutSession, isMutating: isCheckingOut } =
    useSWRMutation(
      "create_checkout_session",
      (key, { arg }) => create_checkout_session({ price_id: arg }),
      { onSuccess: ({ url }) => window.open(url) }
    );

  // console.log(billingConfig);

  const quarterly_pro_plan = billingConfig?.prices?.find(
    (plan) => plan.key === "pro_plan_quarterly"
  );
  const annual_pro_plan = billingConfig?.prices?.find(
    (plan) => plan.key === "pro_plan_annual"
  );

  const quarterly_org_plan = billingConfig?.prices?.find(
    (plan) => plan.key === "org_plan_quarterly"
  );
  return (
    <Tabs defaultValue="quarterly">
      <TabsList>
        <TabsTrigger value="quarterly">Quarterly</TabsTrigger>
        <TabsTrigger value="yearly">Yearly</TabsTrigger>
      </TabsList>
      <TabsContent value="quarterly">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="border-1 rounded-lg p-4 flex flex-col gap-y-4">
            <div className="text-2xl">Starter</div>
            <div className="text-4xl">Free</div>
            <ul className="list-disc list-outside pl-2">
              <li>10 Monthly User Credits</li>
              <li>Research Related Work</li>
              <li>Brainstorm Ideas</li>
              <li>Grant Search </li>
              <li>Proposal Outlines</li>
              <li>Collaborations</li>
              <li>Document Checklist</li>
              <li>Action Items</li>
              <li>Agency Guidelines Q&A</li>
            </ul>
          </div>
          {quarterly_pro_plan && (
            <div className="flex bg-gradient-to-br from-fuchsia to-blue p-1 rounded-xl">
              <div className="border-1 rounded-lg p-4 flex flex-col justify-between gap-y-4 bg-white w-full">
                <div className="flex flex-col gap-y-4">
                  <div className="flex gap-x-2 items-center text-2xl">
                    Pro
                    <div className="border-1 border-fuchsia rounded-xl px-2 text-sm h-fit">
                      Popular
                    </div>
                  </div>
                  <div className="text-4xl">
                    {USDollar.format(
                      quarterly_pro_plan.unit_amount /
                        quarterly_pro_plan.recurring.interval_count /
                        100
                    )}{" "}
                    <span className="text-xl text-gray-500 text-nowrap">
                      {" "}
                      / month
                    </span>{" "}
                    <span className="text-xl text-gray-500 text-nowrap">
                      billed for 3 months
                    </span>
                  </div>
                  <ul className="list-disc list-outside pl-2">
                    <li className="italic font-semibold">
                      Everything From Starter, plus
                    </li>
                    <li>Unlimited Usage</li>
                    <li>Brainstorming Sessions</li>
                    <li>Red-Team Reviews</li>
                    <li>Google Drive Integration</li>
                  </ul>
                </div>
                {isAdmin && (
                  <div className="flex justify-center">
                    <button
                      className="bg-gradient-to-br from-fuchsia to-blue text-white w-fit rounded-2xl px-4 py-1 text-lg hover:opacity-80 flex justify-center items-center gap-x-2"
                      onClick={() =>
                        createCheckoutSession(quarterly_pro_plan.id)
                      }
                      disabled={isCheckingOut}
                    >
                      Upgrade {isCheckingOut && <LoadingSpinner />}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {quarterly_org_plan && (
            <div className="border-1 rounded-lg p-4 flex flex-col justify-between gap-y-4 bg-white w-full">
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-2 items-center text-2xl">
                  Organization
                </div>
                <div className="text-4xl">
                  {USDollar.format(
                    quarterly_org_plan.unit_amount /
                      quarterly_org_plan.recurring.interval_count /
                      100
                  )}{" "}
                  <span className="text-xl text-gray-500 text-nowrap">
                    {" "}
                    / user / month
                  </span>{" "}
                  <span className="text-xl text-gray-500 text-nowrap">
                    billed for 3 months
                  </span>
                </div>
                <ul className="list-disc list-outside pl-2">
                  <li className="italic font-semibold">
                    Everything From Pro, plus
                  </li>
                  <li>Team Projects</li>
                </ul>
              </div>
              {isAdmin && (
                <div className="flex justify-center">
                  <button
                    className="bg-gradient-to-br from-fuchsia to-blue text-white w-fit rounded-2xl px-4 py-1 text-lg hover:opacity-80 flex justify-center items-center gap-x-2"
                    onClick={() => createCheckoutSession(quarterly_org_plan.id)}
                    disabled={isCheckingOut}
                  >
                    Upgrade {isCheckingOut && <LoadingSpinner />}
                  </button>
                </div>
              )}
            </div>
          )}
          <div className="col-span-1 lg:col-span-3 border-1 rounded-lg p-4 flex flex-col gap-y-4">
            <div className="text-2xl">Enterprise</div>
            <div className="text-4xl">Contact Us</div>
          </div>
        </div>
      </TabsContent>
      <TabsContent value="yearly">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="border-1 rounded-lg p-4 flex flex-col gap-y-4">
            <div className="text-2xl">Starter</div>
            <div className="text-4xl">Free</div>
            <ul className="list-disc list-outside pl-2">
              <li>10 Monthly User Credits</li>
              <li>Research Related Work</li>
              <li>Brainstorm Ideas</li>
              <li>Grant Search </li>
              <li>Proposal Outlines</li>
              <li>Collaborations</li>
              <li>Document Checklist</li>
              <li>Action Items</li>
              <li>Agency Guidelines Q&A</li>
            </ul>
          </div>
          {annual_pro_plan && (
            <div className="flex bg-gradient-to-br from-fuchsia to-blue p-1 rounded-xl">
              <div className="border-1 rounded-lg p-4 flex flex-col justify-between gap-y-4 bg-white w-full">
                <div className="flex flex-col gap-y-4">
                  <div className="flex gap-x-2 items-center text-2xl">
                    Pro
                    <div className="border-1 border-fuchsia rounded-xl px-2 text-sm h-fit">
                      Popular
                    </div>
                  </div>
                  <div className="text-4xl">
                    {USDollar.format(
                      annual_pro_plan.unit_amount /
                        annual_pro_plan.recurring.interval_count /
                        100
                    )}{" "}
                    <span className="text-xl text-gray-500 text-nowrap">
                      / year
                    </span>
                  </div>
                  <ul className="list-disc list-outside pl-2">
                    <li className="italic font-semibold">
                      Everything From Starter, plus
                    </li>
                    <li>Unlimited Usage</li>
                    <li>Brainstorming Sessions</li>
                    <li>Red-Team Reviews</li>
                    <li>Google Drive Integration</li>
                  </ul>
                </div>
                {isAdmin && (
                  <div className="flex justify-center">
                    <button
                      className="bg-gradient-to-br from-fuchsia to-blue text-white w-fit rounded-2xl px-4 py-1 text-lg hover:opacity-80 flex justify-center items-center gap-x-2"
                      onClick={() => createCheckoutSession(annual_pro_plan.id)}
                      disabled={isCheckingOut}
                    >
                      Upgrade {isCheckingOut && <LoadingSpinner />}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* {ORG_PLAN && (
            <div className="border-1 rounded-lg p-4 flex flex-col justify-between gap-y-4 bg-white w-full">
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-2 items-center text-2xl">
                  Organization
                </div>
                <div className="text-4xl">
                  {USDollar.format(ORG_PLAN.unit_amount / 100)}{" "}
                  <span className="text-xl text-gray-500 text-nowrap">
                    {" "}
                    / per user / per month for 3 months
                  </span>
                </div>
                <ul className="list-disc list-outside pl-2">
                  <li className="italic font-semibold">
                    Everything From Pro, plus
                  </li>
                  <li>Team Projects</li>
                </ul>
              </div>
              {isAdmin && (
                <div className="flex justify-center">
                  <button
                    className="bg-gradient-to-br from-fuchsia to-blue text-white w-fit rounded-2xl px-4 py-1 text-lg hover:opacity-80 flex justify-center items-center gap-x-2"
                    onClick={() => createCheckoutSession(ORG_PLAN.id)}
                    disabled={isCheckingOut}
                  >
                    Upgrade {isCheckingOut && <LoadingSpinner />}
                  </button>
                </div>
              )}
            </div>
          )} */}
          <div className="col-span-1 lg:col-span-3 border-1 rounded-lg p-4 flex flex-col gap-y-4">
            <div className="text-2xl">Enterprise</div>
            <div className="text-4xl">Contact Us</div>
          </div>
        </div>
      </TabsContent>
    </Tabs>
  );
}
