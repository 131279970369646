import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useSettings } from "@/hooks/use-settings";
import { useToast } from "@/hooks/use-toast";
import { accept_org_invite, get_invite_code_info } from "api/organization";
import { ErrorPage, LoadingPage, Title } from "components";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function Invite() {
  const { settings } = useSettings();
  const [forceJoin, setForceJoin] = useState(false);
  const [isOrgAtFullCapacity, setIsOrgAtFullCapacity] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const org_invite_code = searchParams.get("org_invite_code");
  const requiredToSetForceJoin = settings?.org_tier === "pro";
  const { toast } = useToast();
  const navigate = useNavigate();

  const {
    data: inviteCodeInfo,
    isLoading: isGettingInviteCodeInfo,
    error: inviteCodeInfoError,
  } = useSWR(`invite_code_info/${org_invite_code}`, () =>
    get_invite_code_info({ code: org_invite_code })
  );

  const { trigger: acceptOrgInvite } = useSWRMutation(
    "accept_org_invite",
    () =>
      accept_org_invite({
        invite_code: org_invite_code,
        force_join: forceJoin,
      }),
    {
      onSuccess: () => {
        toast({
          title: "Congratulations!",
          description: "You have joined an organization!",
        });
        mutate("get_settings");
        mutate("get_customer_plan");
        navigate("/");
      },
      onError: (err) => {
        if (err?.response?.status === 409) {
          setIsOrgAtFullCapacity(true);
        }
      },
    }
  );

  if (isGettingInviteCodeInfo) return <LoadingPage />;
  if (inviteCodeInfoError?.response?.data === "Invitation code not found")
    return (
      <div>
        There was an issue getting the invitation code. Make sure you have the
        correct link or have the organization resend a new invite.
      </div>
    );
  if (inviteCodeInfoError) return <ErrorPage />;

  return (
    <div className="flex h-full gap-4 justify-center items-center">
      <div className="bg-light-grey flex flex-col max-w-lg w-full rounded-lg p-2 gap-4">
        <div className="flex gap-x-2 items-center">
          <img src={inviteCodeInfo.profile_photo_url} className="w-8 h-8" />
          <span className="font-semibold text-lg">You Are Invited!</span>
        </div>
        <span>
          <span>You have been invited to join </span>
          <span className="font-semibold">{inviteCodeInfo.org_name}</span>
          <span> as a {inviteCodeInfo.role} by </span>
          <span className="font-semibold">{inviteCodeInfo.inviter_name}</span>
        </span>
        <div className="flex justify-center">
          <button
            onClick={acceptOrgInvite}
            disabled={requiredToSetForceJoin && !forceJoin}
            className="btn btn-fuchsia"
          >
            Join as {inviteCodeInfo.role}
          </button>
        </div>
        {requiredToSetForceJoin && (
          <div className="items-top flex space-x-2">
            <Checkbox
              id="forceJoin"
              checked={forceJoin}
              onCheckedChange={setForceJoin}
            />
            <div className="grid gap-1.5 leading-none">
              <Label htmlFor="forceJoin">Cancel your current membership.</Label>
              <p className="text-sm text-muted-foreground">
                You will NOT get a refund for your current billing period.
              </p>
            </div>
          </div>
        )}
      </div>
      {/* <Title
        className="text-4xl font-semibold"
        value="Invite to an Organization"
      />
      <p>
        You have been invited to join{" "}
        <span className="font-semibold">{inviteCodeInfo.org_name}</span> by{" "}
        {inviteCodeInfo.inviter_name}.
      </p>
      {requiredToSetForceJoin && (
        <div className="items-top flex space-x-2">
          <Checkbox
            id="forceJoin"
            checked={forceJoin}
            onCheckedChange={setForceJoin}
          />
          <div className="grid gap-1.5 leading-none">
            <Label htmlFor="forceJoin">Cancel your current membership.</Label>
            <p className="text-sm text-muted-foreground">
              You will NOT get a refund for your current billing period.
            </p>
          </div>
        </div>
      )}
      <div>
        <button
          onClick={acceptOrgInvite}
          disabled={requiredToSetForceJoin && !forceJoin}
          className="btn btn-fuchsia"
        >
          Join as {inviteCodeInfo.role}
        </button>
      </div>
      <Dialog open={isOrgAtFullCapacity} onOpenChange={setIsOrgAtFullCapacity}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Organization is at full capacity</DialogTitle>
            <DialogDescription>
              The organization you are trying to join is at full capacity. Try
              messaging the admins to add more seats.
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog> */}
    </div>
  );
}
