import axiosInstance from "./axiosInstance";

export const billing_config = async () => {
  const response = await axiosInstance({
    url: "/billing/config",
    method: "GET",
  });
  return response.data;
};

export const get_customer_plan = async () => {
  const response = await axiosInstance({
    url: "/billing/customer-plan",
    method: "GET",
  });
  return response.data;
};

export const create_customer_portal = async () => {
  const response = await axiosInstance({
    url: "/billing/customer-portal",
    method: "POST",
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 303,
  });
  return response.data;
};

export const create_checkout_session = async ({ price_id }) => {
  const response = await axiosInstance({
    url: "/billing/checkout-session",
    method: "POST",
    data: { price_id: price_id },
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 303,
  });
  return response.data;
};

export const upgrade_plan = async ({
  stripe_price_id,
  idempotency_key,
  force_upgrade,
  quantity,
  proration_date,
}) => {
  const response = await axiosInstance({
    url: "/billing/plan/upgrade",
    method: "POST",
    data: {
      idempotency_key: idempotency_key,
      force_upgrade: force_upgrade,
      stripe_price_id: stripe_price_id,
      quantity: quantity,
      proration_date: proration_date,
    },
  });
  return response.data;
};

export const downgrade_plan = async ({
  stripe_price_id,
  idempotency_key,
  quantity,
}) => {
  const response = await axiosInstance({
    url: "/billing/plan/downgrade",
    method: "POST",
    data: {
      stripe_price_id: stripe_price_id,
      idempotency_key: idempotency_key,
      quantity: quantity,
    },
  });
  return response.data;
};

export const cancel_plan = async ({ idempotency_key, force_cancel }) => {
  const response = await axiosInstance({
    url: "/billing/plan/cancel",
    method: "POST",
    data: {
      cancel_at_period_end: true,
      idempotency_key: idempotency_key,
      force_cancel: force_cancel,
    },
  });
  return response.data;
};

export const uncancel_plan = async ({ idempotency_key, force_cancel }) => {
  const response = await axiosInstance({
    url: "/billing/plan/cancel",
    method: "POST",
    data: {
      cancel_at_period_end: false,
      idempotency_key: idempotency_key,
      force_cancel: force_cancel,
    },
  });
  return response.data;
};

export const preview_billing_changes = async ({
  idempotency_key,
  stripe_price_id,
  quantity,
}) => {
  const response = await axiosInstance({
    url: "/billing/invoice/preview_changes",
    method: "POST",
    data: {
      idempotency_key: idempotency_key,
      stripe_price_id: stripe_price_id,
      quantity: quantity,
    },
  });
  return response.data;
};

export const release_schedule = async ({ idempotency_key }) => {
  const response = await axiosInstance({
    url: "/billing/release_schedule",
    method: "PUT",
    data: {
      idempotency_key: idempotency_key,
    },
  });
  return response.data;
};
