import {
  attach_file_to_required_file,
  attach_url_to_required_file,
  delete_required_file,
  edit_required_file,
  get_attached_file_from_required_file,
} from "api";

import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { MdDelete } from "react-icons/md";
import { FaCloudUploadAlt, FaExpand } from "react-icons/fa";
import { TableCell, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDropzone } from "react-dropzone";
import { Label } from "@/components/ui/label";

export default function File({
  file_id,
  file_info,
  file_uploaded,
  completed,
  description,
  projectId,
  hasEditorAccess,
}) {
  // console.log(file_uploaded)
  const { data: fileURL } = useSWR(
    file_uploaded ? `get_file_url?${projectId}/${file_id}` : null,
    () =>
      get_attached_file_from_required_file({
        project_id: projectId,
        file_id: file_id,
      })
  );
  const [tempFileInfo, setTempFileInfo] = useState(file_info);
  const [tempDescription, setTempDescription] = useState(description);

  const [url, setUrl] = useState("");

  const { acceptedFiles, getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      accept: { "application/pdf": [".pdf"] },
      maxFiles: 1,
      noClick: true,
      noKeyboard: true,
    });

  const { trigger: attachFile } = useSWRMutation(
    "attach_file",
    () => {
      console.log(tempFileInfo);
    },
    // attach_file_to_required_file({
    //   project_id: projectId,
    //   file_id: file_id,
    //   file_name: url,
    // })
    { onSuccess: (d) => console.log(d) }
  );

  const { trigger: attachURL } = useSWRMutation(
    "attach_url",
    () =>
      attach_url_to_required_file({
        project_id: projectId,
        file_id: file_id,
        url: url,
      }),
    { onSuccess: (d) => console.log(d) }
  );

  // const onUploadFile = () => {
  //   const { name } = acceptedFiles[0];
  //   trigger({
  //     project_id: projectId,
  //     upload_type: "upload",
  //     filename: name,
  //     file: acceptedFiles[0],
  //   });
  // };

  const { error: toggleCompleteError, trigger: toggleComplete } =
    useSWRMutation(
      `toggle_complete`,
      () =>
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          complete: !completed,
        }),
      {
        onSuccess: () => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, completed: !completed }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: updateFileInfoError, trigger: updateFileInfo } =
    useSWRMutation(
      `update_file_info`,
      (key, { arg }) => {
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          name: arg,
        });
        return arg;
      },
      {
        onSuccess: (new_file_info) => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, file_info: new_file_info }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: updateDescriptionError, trigger: updateDescription } =
    useSWRMutation(
      `update_description`,
      (key, { arg }) => {
        if (arg === description) return arg;
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          description: arg,
        });
        return arg;
      },
      {
        onSuccess: (new_description) => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, description: new_description }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: deleteRequiredFileError, trigger: deleteRequiredFile } =
    useSWRMutation(
      `delete_required_file`,
      () => delete_required_file({ project_id: projectId, file_id: file_id }),
      {
        onSuccess: () => {
          mutate(
            `get_required_files/${projectId}`,
            (d) => d.filter((file) => file.file_id !== file_id),
            { revalidate: false }
          );
        },
      }
    );

  const handleUpdateName = () => {
    if (file_info !== tempFileInfo) updateFileInfo(tempFileInfo);
  };

  const handleUpdateDescription = () => {
    if (description !== tempDescription) updateDescription(tempDescription);
  };

  return (
    <TableRow key={file_id} className="group">
      <TableCell className="text-center">
        <Checkbox
          checked={completed}
          onClick={toggleComplete}
          disabled={!hasEditorAccess}
        />
      </TableCell>
      <TableCell className="relative">
        <Input
          placeholder={"New file"}
          value={tempFileInfo || ""}
          onChange={(e) => setTempFileInfo(e.target.value)}
          onBlur={handleUpdateName}
          maxLength={250}
          readOnly={!hasEditorAccess}
        />
        <Dialog>
          <DialogTrigger className="flex gap-x-2 items-center hover:text-magenta absolute top-1/2 -translate-y-1/2 right-0 invisible group-hover:visible bg-light-grey p-1 rounded-md hover:text-fuchsia">
            OPEN <FaExpand />
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit File Information</DialogTitle>
              <DialogDescription>
                Edit your file information here.
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col gap-y-2">
              <Input
                placeholder={"New file"}
                value={tempFileInfo || ""}
                onChange={(e) => setTempFileInfo(e.target.value)}
                onBlur={handleUpdateName}
                maxLength={250}
                readOnly={!hasEditorAccess}
              />
              <div className="flex gap-x-2 items-center">
                <Label for="completed" className="font-semibold">
                  Completed
                </Label>
                <Checkbox
                  id="completed"
                  checked={completed}
                  onClick={toggleComplete}
                  disabled={!hasEditorAccess}
                />
              </div>
              <Textarea
                placeholder={"Enter a description..."}
                value={tempDescription || ""}
                onChange={(e) => setTempDescription(e.target.value)}
                onBlur={handleUpdateDescription}
                readOnly={!hasEditorAccess}
              />

              {file_uploaded ? (
                <div className="text-sm">
                  <p className="font-semibold">Attached File</p>
                  <span>{fileURL?.url}</span>
                </div>
              ) : (
                // <>
                //   <Label for="attach-file-method" className="font-semibold">
                //     Attach file
                //   </Label>
                //   <Select id="attach-file-method">
                //     <SelectTrigger>
                //       <SelectValue />
                //     </SelectTrigger>
                //     <SelectContent>
                //       <SelectItem value="url">Url</SelectItem>
                //     </SelectContent>
                //   </Select>
                // </>
                <Tabs defaultValue="upload">
                  <span className="text-sm mr-2 font-semibold">
                    Attach a file
                  </span>
                  <TabsList>
                    <TabsTrigger value="upload">by Upload</TabsTrigger>
                    <TabsTrigger value="url">by Url</TabsTrigger>
                  </TabsList>
                  <TabsContent value="upload">
                    <span className="text-sm text-muted-foreground">
                      Attach a pdf, docx, tex, pptx, or xlsx file
                    </span>
                    <div
                      className={
                        "w-full p-8 border-4 border-dashed rounded-lg " +
                        (isDragActive ? "border-fuchsia" : "border-zinc-200")
                      }
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      {acceptedFiles.length ? (
                        <div className="flex flex-col items-center justify-center ">
                          <FaCloudUploadAlt className="w-20 h-20" />
                          <p className="text-xl">{acceptedFiles[0].path}</p>
                          <span className="h-6"></span>
                          <button
                            className="btn-fuchsia text-xl"
                            onClick={attachFile}
                          >
                            Click to upload file
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center ">
                          <FaCloudUploadAlt className="w-20 h-20" />
                          <button
                            onClick={open}
                            className="btn-offblack text-xl"
                          >
                            Click to browse files
                          </button>
                        </div>
                      )}
                    </div>
                  </TabsContent>
                  <TabsContent value="url">
                    <span className="text-sm">Attach a URL</span>
                    <div className="flex gap-x-2">
                      <Input
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        onBlur={(e) => console.log(e)}
                      />
                      <button
                        className="btn btn-fuchsia"
                        disabled={!url.length}
                        onClick={attachURL}
                      >
                        Attach
                      </button>
                    </div>
                  </TabsContent>
                </Tabs>
              )}

              {hasEditorAccess && (
                <div>
                  <button
                    onClick={deleteRequiredFile}
                    className="text-red-500 flex items-center text-base hover:underline mt-8"
                    disabled={!hasEditorAccess}
                  >
                    <MdDelete /> Delete
                  </button>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}
