import axiosInstance from "./axiosInstance";

export const get_action_items = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/admin/action_items",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

export const delete_action_item = async ({ project_id, action_item_id }) => {
  const response = await axiosInstance({
    url: "/project/admin/action_items",
    method: "DELETE",
    data: {
      project_id: project_id,
      action_item_id: action_item_id,
    },
  });
  return response.data;
};

/**
 *
 * @param {*} project_id The unique id of the project
 * @param {*} action_item The action item text
 * @param {*} description The description of the the action item text the user can use
 * @param {*} due_date The due date of the action item. Must be in form ‘YYYY-MM-DD’
 * @param {*} notify An indicator of if the user would like us to notify the assigned user. If True then due_date must be at least one day in the future and assignee_uids must not be null
 * @param {*} assignee_uids A list of the user id’s to be assigned to this action item. The users must be in the project already
 * @returns
 */

export const add_action_item = async ({
  project_id,
  action_item,
  description,
  due_date,
  notify,
  assignee_uids,
}) => {
  const response = await axiosInstance({
    url: "/project/admin/action_items/add",
    method: "POST",
    data: {
      project_id: project_id,
      action_item: action_item,
      description: description,
      due_date: due_date,
      notify: notify,
      assignee_uids,
    },
  });
  return response.data;
};

/**
 *
 * @param {*} project_id The unique id of the project
 * @param {*} action_item_id The unique id for this project for this action item
 * @param {*} action_item The action item text
 * @param {*} description The description of the the action item text the user can use
 * @param {*} due_date The due date of the action item. Must be in form ‘YYYY-MM-DD’
 * @param {*} notify An indicator of if the user would like us to notify the assigned user. If True then due_date must be at least one day in the future and assignee_uids must not be null
 * @param {*} assignee_uids A list of the user id’s to be assigned to this action item. The users must be in the project already
 * @param {*} complete Indicates if the action item is complete or not
 * @param {*} priority The priority level of the item. Accepts values [1,3] inclusive and NULL
 */
export const edit_action_item = async ({
  project_id,
  action_item_id,
  action_item,
  description,
  due_date,
  notify,
  assignee_uids,
  complete,
  priority,
}) => {
  const response = await axiosInstance({
    url: "/project/admin/action_items/edit",
    method: "POST",
    data: {
      project_id: project_id,
      action_item_id: action_item_id,
      action_item: action_item,
      description: description,
      due_date: due_date,
      notify: notify,
      assignee_uids: assignee_uids,
      complete: complete,
      priority: priority,
    },
  });
  return response.data;
};

export const add_assigned_user = async ({
  project_id,
  action_item_id,
  assignee_uids,
}) => {
  const response = await axiosInstance({
    url: "/project/admin/action_items/edit_users",
    method: "POST",
    data: {
      project_id: project_id,
      action_item_id: action_item_id,
      assignee_uids: assignee_uids,
    },
  });
  return response.data;
};

export const remove_assigned_user = async ({
  project_id,
  action_item_id,
  assignee_uids,
}) => {
  const response = await axiosInstance({
    url: "/project/admin/action_items/edit_users",
    method: "DELETE",
    data: {
      project_id: project_id,
      action_item_id: action_item_id,
      assignee_uids: assignee_uids,
    },
  });
  return response.data;
};

export const get_required_files = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/admin/required_files",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

export const delete_required_file = async ({ project_id, file_id }) => {
  const response = await axiosInstance({
    url: "/project/admin/required_files",
    method: "DELETE",
    data: {
      project_id: project_id,
      file_id: file_id,
    },
  });
  return response.data;
};

export const add_required_file = async ({ project_id, name, description }) => {
  const response = await axiosInstance({
    url: "/project/admin/required_files/add",
    method: "POST",
    data: {
      project_id: project_id,
      name: name,
      description: description,
    },
  });
  return response.data;
};

export const edit_required_file = async ({
  project_id,
  file_id,
  name,
  description,
  complete,
}) => {
  const response = await axiosInstance({
    url: "/project/admin/required_files/edit",
    method: "POST",
    data: {
      project_id: project_id,
      file_id: file_id,
      name: name,
      description: description,
      complete: complete,
    },
  });
  return response.data;
};

export const recommend_required_files = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/admin/required_files/recommend",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

export const get_attached_file_from_required_file = async ({
  project_id,
  file_id,
}) => {
  const response = await axiosInstance({
    url: "/project/admin/required_files/url",
    method: "GET",
    params: { project_id: project_id, file_id: file_id },
  });
  return response.data;
};

export const attach_file_to_required_file = async ({
  project_id,
  file_id,
  file_name,
  file_type,
}) => {
  const response = await axiosInstance({
    url: "/project/admin/required_files/attach",
    method: "POST",
    data: {
      project_id: project_id,
      file_id: file_id,
      method: "file",
      file_name: file_name,
      file_type: file_type,
    },
  });

  return response.data;
};

export const attach_url_to_required_file = async ({
  project_id,
  file_id,
  url,
}) => {
  const response = await axiosInstance({
    url: "/project/admin/required_files/attach",
    method: "POST",
    data: {
      project_id: project_id,
      file_id: file_id,
      method: "url",
      url: url,
    },
  });

  return response.data;
};
