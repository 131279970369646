/* 
    Name: http-common.js
    Purpose: Set up axios requests with automatic caching, refreshing,
             and re-running of failed requests after refreshing.
*/

import axios from "axios";
import { useAuthStore } from "stores/useAuthStore";
import { refresh_token } from "./auth";
import { mutate } from "swr";

// Set up uncached axios request
// On local development, we use react-script's proxy (set in package.json)
// On production, we set the base URL to include /api and let nginx do the proxy on server side
const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "" : "/api/",
  withCredentials: true,
});

// // Define the structure of a retry queue item
// interface RetryQueueItem {
//   resolve: (value?: any) => void;
//   reject: (error?: any) => void;
//   config: AxiosRequestConfig;
// }

// Create a list to hold the request queue
const refreshAndRetryQueue = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    console.log(error);

    if (error.response && error.response.status === 418) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          console.log("refreshing token");

          // Refresh the access token
          // const newAccessToken = await refreshAccessToken();
          const response = await axios.post("/auth/refresh");
          console.log(response);

          // Update the request headers with the new access token
          // error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;

          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosInstance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.log(refreshError);
          // Handle token refresh error

          // You can clear all storage and redirect the user to the login page
          // mutate(() => true, undefined, { revalidate: true });
          window.location.href = "/login";
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }

      // Add the original request to the queue
      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    // Return a Promise rejection if the status code is not 401
    return Promise.reject(error);
  }
);

// export const cancelToken = axios.CancelToken.source();

// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve();
//     }
//   });

//   failedQueue = [];
// };
// let i = 0;

// axiosInstance.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   async function (error) {
//     if (i++ > 3) {
//       return Promise.reject();
//     }
//     if (error.config.url === "/auth/refresh") {
//       return Promise.reject(error);
//     }

//     const originalRequest = error.config;

//     if (originalRequest.url === "/user/login") {
//       return Promise.reject(error);
//     }

//     // If error is an authentication error, try to refresh token and redo api request
//     // We set expired JWT error to code 418
//     if (error.response?.status === 418 && !originalRequest._retry) {
//       console.log("");
//       if (isRefreshing) {
//         return new Promise(function (resolve, reject) {
//           failedQueue.push({ resolve, reject });
//         })
//           .then(() => {
//             return axiosInstance(originalRequest);
//           })
//           .catch((err) => {
//             return Promise.reject(new Error(err));
//           });
//       }

//       originalRequest._retry = true;

//       isRefreshing = true;

//       return new Promise(function (resolve, reject) {
//         refresh_token()
//           .then((response) => {
//             // TODO: Check if need to set refresh_token here
//             isRefreshing = false;
//             processQueue(null);
//             return resolve(axiosInstance(originalRequest));
//           })
//           .catch((err) => {
//             // If refreshing token fails, clear authStore and redirect to /login
//             isRefreshing = false;
//             mutate(() => true, undefined, { revalidate: true });
//             processQueue(err);
//             // window.location.href = "/login";
//           });
//       });
//     }

//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
