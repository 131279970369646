import axiosInstance from "./axiosInstance";

export const list_templates = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/write/template/list",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

export const get_template_info = async ({ template_id }) => {
  try {
    const response = await axiosInstance({
      url: "/write/template/",
      method: "GET",
      params: {
        template_id: template_id,
        info: "outline",
      },
    });
    return response.data;
  } catch (e) {
    const response = await axiosInstance({
      url: "/write/template/",
      method: "GET",
      params: {
        template_id: template_id,
        info: "template",
      },
    });
    return response.data;
  }
};

export const create_template = async ({ project_id, template_name }) => {
  const response = await axiosInstance({
    url: "/write/template/create",
    method: "POST",
    data: {
      project_id: project_id,
      template_name: template_name,
    },
  });

  return response.data;
};

export const delete_template = async ({ template_id }) => {
  const response = await axiosInstance({
    url: "/write/template/remove",
    method: "DELETE",
    data: {
      template_id: template_id,
    },
  });
  return response.data;
};

export const update_template_name = async ({ template_id, name }) => {
  const response = await axiosInstance({
    url: "/write/template/name/edit",
    method: "PUT",
    data: {
      template_id: template_id,
      name: name,
    },
  });
  return response.data;
};

/**
 *
 * @param {*} rank_position The rank to put the heading in. Follows the key structure rank_at_end, prev_heading_id, next_heading_id. If rank_at_end is True then we add to the end (no need for other keys). If false then must provide the surrounding two heading_ids. If adding to the top then leave prev_heading_id value as None. Default: Adds to end
 * @returns
 */

export const add_heading = async ({
  template_id,
  depth,
  heading,
  rank_position,
}) => {
  const response = await axiosInstance({
    url: "/write/template/heading/add",
    method: "POST",
    data: {
      template_id: template_id,
      depth: depth,
      heading: heading,
      rank_position: rank_position,
    },
  });
  return response.data;
};

export const add_batch_headings = async ({ template_id, headings }) => {
  const response = await axiosInstance({
    url: "/write/template/heading/add/batch",
    method: "POST",
    data: {
      template_id: template_id,
      headings: headings,
    },
  });
  return response.data;
};

export const edit_heading = async ({
  template_id,
  project_id,
  depth,
  heading,
  heading_id,
  outline_guidance,
  outline,
}) => {
  const response = await axiosInstance({
    url: "/write/template/heading/edit",
    method: "POST",
    data: {
      template_id: template_id,
      project_id: project_id,
      heading_id: heading_id,
      depth: depth,
      heading: heading,
      outline_guidance: outline_guidance,
      outline,
    },
  });
  return response.data;
};

export const edit_heading_outline = async ({
  template_id,
  heading_id,
  outline,
}) => {
  const response = await axiosInstance({
    url: "/write/template/heading/outline/edit",
    method: "POST",
    data: {
      template_id: template_id,
      heading_id: heading_id,
      outline: outline,
    },
  });
  return response.data;
};

export const rerank_heading = async ({
  template_id,
  heading_id,
  rank_position,
}) => {
  const response = await axiosInstance({
    url: "/write/template/heading/rerank",
    method: "POST",
    data: {
      template_id: template_id,
      heading_id: heading_id,
      rank_position: rank_position,
    },
  });
  return response.data;
};

export const remove_heading = async ({ template_id, heading_id }) => {
  const response = await axiosInstance({
    url: "/write/template/heading/remove",
    method: "DELETE",
    data: {
      template_id: template_id,
      heading_id: heading_id,
    },
  });
  return response.data;
};

export const generate_outline = async ({ template_id }) => {
  const response = await axiosInstance({
    url: "/process/write/template/outline/generate",
    method: "POST",
    data: {
      template_id: template_id,
    },
  });
  return response.data;
};

export const regenerate_outline_for_headings = async ({
  template_id,
  heading_ids,
  feedback,
}) => {
  const response = await axiosInstance({
    url: "/process/write/template/outline/regenerate",
    method: "POST",
    data: {
      template_id: template_id,
      heading_ids: heading_ids,
      feedback: feedback,
    },
  });
  return response.data;
};

export const export_template_to_google_drive = async ({
  template_id,
  export_type,
  google_file_id,
}) => {
  const response = await axiosInstance({
    url: "/write/template/google/export",
    method: "POST",
    data: {
      template_id: template_id,
      export_type: export_type,
      google_file_id: google_file_id,
    },
  });
  return response.data;
};
